import React, { useState } from "react";
import Layout from "../components/global/Layout/Layout";
import { Container, Form, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";

const initialState = {
  path: "",
  heading: "",
  subHeading: "",
  activePath: { features: [] },
};
const GenerateFeatureUrls: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      allHotels: allHotel {
        nodes {
          features {
            tab_title
            sub_features {
              tab_title
            }
          }
          path {
            alias
          }
        }
      }
    }
  `);
  const hotels = data.allHotels.nodes.reduce(
    (a: any, v: any) => ({ ...a, [v.path.alias]: v }),
    {}
  );
  const [state, setState] = useState(initialState);
  const handleInputChange = (e: any) => {
    const value = e.target.value?.toLowerCase();
    const id = e.target.id;
    if (id === "path") {
      const path = value.split("#")[0];
      if (!value) {
        setState(initialState);
      } else {
        let hotelPath = path.split(
          path.includes("localhost") ? ":8000" : ".com"
        )[1];
        if (hotelPath.charAt(hotelPath.length - 1) === "/") {
          hotelPath = hotelPath?.slice(0, -1);
        }
        setState({
          ...state,
          activePath: hotels[hotelPath],
          path: path,
        });
      }
    } else if (id === "heading" && !value) {
      setState({ ...state, subHeading: "" });
    } else {
      setState({ ...state, [id]: value });
    }
  };
  const getString = (arr: Array<string>) => {
    let string = "";
    for (let i = 0; i < arr.length; i++) {
      if (i === arr.length - 1) {
        return string + arr[i];
      }
      if (arr[i].match(/^[^a-zA-Z]+$/) && arr[i + 1].match(/^[^a-zA-Z]+$/)) {
        continue;
      } else {
        if (!arr[i].match(/^[^a-zA-Z]+$/)) {
          string += arr[i];
          continue;
        } else {
          string += "-";
        }
      }
    }
    return string;
  };
  const url = `${state.path.split("#")[0]}#hoteldetails_${getString(
    state.heading.split("")
  )}${state.subHeading ? `_${getString(state.subHeading.split(""))}` : ""}`;
  return (
    <Layout>
      <Container fluid="sm" className="pt-5 mb-5 p-5">
        <Row className="px-5">
          <Col lg={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Hotel path<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                placeholder="Hotel path"
                id="path"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Feature Heading
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                placeholder="Heading"
                id="heading"
                onChange={handleInputChange}
                disabled={!state.path}
              >
                <option></option>
                {state.activePath?.features.map((feature: any) => (
                  <option key={feature.tab_title}>{feature?.tab_title}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mb-3">
              <Form.Label>Feature Sub Heading</Form.Label>
              <Form.Control
                as="select"
                placeholder="Sub Heading"
                id="subHeading"
                onChange={handleInputChange}
                disabled={!state.path || !state.heading}
              >
                <option></option>
                {state.activePath?.features.map(
                  (feature: any) =>
                    feature.tab_title.toLowerCase() === state.heading &&
                    feature.sub_features?.map((subFeature: any) => (
                      <option key={subFeature.tab_title}>
                        {subFeature.tab_title}
                      </option>
                    ))
                )}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row className="px-5">
          <Col lg={12}>
            <div>
              {state.path && state.heading ? (
                <a
                  style={{ textDecoration: "underline" }}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {url}
                </a>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default GenerateFeatureUrls;
